<template>
  <v-container class="py-0">
    <!-- <restaurant-slideshow :restaurant-id="restaurantId" /> -->
    <!-- <restaurant-banner :restaurant-id="restaurantId" /> -->
    <v-img :src="restaurantData.primaryPhoto" :height="'62vh'" width="100%" />
    <div class="my-3 py-3">
      <div class="mb-4">
        <h1 class="display-1">{{restaurantData.name}}</h1><v-chip outlined :ripple="false" label>{{ restaurantData.official ? 'Verified' : 'Submitted by contributor' }}</v-chip>
      </div>
      <v-rating v-if="restaurantData.rating" readonly :value="restaurantData.rating" color="primary" />
      <p class="ma-0 pa-0">{{restaurantData.description}}</p>
      <RestaurantOpenHour v-if="restaurantData.businessHours" :business-hours="restaurantData.businessHours.hours" />
      <p v-if="restaurantData.address" v-text="restaurantData.address" />
      <span v-if="restaurantData.contacts" class="font-weight-bold">Contacts</span><br /><contact-viewer :contacts="restaurantData.contacts" v-if="restaurantData.contacts" />
    </div>
    <div class="my-3">
      <h2 class="headline">Gallery</h2>
      <FirebaseFirestoreGallery :collection-path="collectionPath" limit="8" />
    </div>
    <div class="my-3">
      <h2 class="headline">Menu</h2>
      <restaurant-menu :restaurant-id="restaurantId" />
    </div>
    <div class="my-3">
      <h2 class="headline">Business Hours</h2>
      <OpeningHourTable :restaurant-id="restaurantId" />
    </div>
    <div class="my-3">
      <h2 class="headline">Services</h2>
      <v-chip class="mr-2 mb-2" v-for="service in restaurantData.features" :key="service" outlined :ripple="false">{{ service }}</v-chip>
    </div>
    <div class="my-3" v-if="restaurantData.address">
      <h2 class="headline">Location</h2>
      <GoogleMaps :location-name="restaurantData.name" :address="restaurantData.address" />
    </div>
    <!-- <div class="my-3"> -->
      <!-- <h2 class="headline">From our sponsors</h2> -->
      <!-- <ad -->
        <!-- :ad-client="adClient" -->
        <!-- ad-format="auto" -->
        <!-- :ad-slot="adSlot" -->
        <!-- full-width-responsive="true" -->
        <!-- style="" -->
      <!-- /> -->
    <!-- </div> -->
  </v-container>
</template>

<script>
import firebase from '../plugins/firebase'
// import RestaurantImageGrid from '../components/RestaurantView/RestaurantImageGrid/RestaurantImageGrid'
import RestaurantContacts from '../components/RestaurantView/RestaurantContacts/RestaurantContacts'
import RestaurantMenu from '../components/RestaurantView/RestaurantMenu/RestaurantMenuTable'
import GoogleMaps from '@/components/GoogleEmbedMaps'
import RestaurantOpenHour from '@/components/RestaurantView/RestaurantOpenHour'
import FirebaseFirestoreGallery from '@/components/Gallery/FirebaseFirestoreGallery'
import OpeningHourTable from '@/components/RestaurantView/OpeningHourComplete/OpeningHourComplete'

// import VueAdsense from '../plugins/vue-adsense/main'

export default {
  name: 'RestaurantView',

  props: {
    'restaurant-id': String
  },

  components: {
    // 'restaurant-banner': RestaurantImageGrid,
    'contact-viewer': RestaurantContacts,
    'restaurant-menu': RestaurantMenu,
    GoogleMaps,
    RestaurantOpenHour,
    FirebaseFirestoreGallery,
    OpeningHourTable
    // ad: VueAdsense
  },

  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    collectionPath () {
      return `/restaurants/${this.restaurantId}/photos`
    }
  },

  data () {
    return ({
      loading: false,
      bannerPhotos: [],
      restaurantData: {},

      adClient: process.env.VUE_APP_GOOGLE_AD_CLIENT,
      adSlot: '4637260843'
    })
  },

  watch: {
    restaurantData (val) {
      document.title = `${val.name} - Amborjo`
    }
  },

  methods: {
    getRestaurantData () {
      var restaurantDocumentReference = firebase.firestore().doc(`/restaurants/${this.restaurantId}`)

      return new Promise((resolve, reject) => {
        restaurantDocumentReference.get().then(doc => {
          resolve(doc.data())
        }).catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    this.$data.loading = true

    this.$data.restaurantData = await this.getRestaurantData()
    this.$data.loading = false
  }
}
</script>
