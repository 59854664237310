<template>
  <v-data-table
    dense
    disable-pagination
    hide-default-footer
    :items="menu"
    :headers="tableHeaders"
  >
    <template #item="{ item }">
      <tr><td v-text="item.name"><td v-text="formatCurrency(item.price)" /></tr>
    </template>
    <!-- <template #[`item.price`]="{ item }">
      {{ formatCurrency(item.price) }}
    </template> -->
  </v-data-table>
</template>

<script>
import firebase from '@/plugins/firebase'

export default {
  name: 'RestaurantMenu',

  props: {
    'restaurant-id': {
      type: String,
      required: true
    }
  },

  data () {
    return ({
      loading: false,
      menu: [],
      tableHeaders: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Price',
          value: 'price',
          filterable: false
        }
      ]
    })
  },

  async mounted () {
    this.$data.loading = true
    this.$data.menu = await this.getMenu()
    this.$data.loading = false
  },

  methods: {
    formatCurrency (num) {
      return Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(num)
    },

    getMenu () {
      return new Promise((resolve, reject) => {
        firebase.firestore().collection(`/restaurants/${this.restaurantId}/products`).get()
          .then(querySnapshot => {
            var items = []

            querySnapshot.docs.forEach(doc => {
              items.push(Object.assign({ id: doc.id }, doc.data()))
            })

            resolve(items)
          }).catch(e => { reject(e) })
      })
    }
  }
}
</script>
