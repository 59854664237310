<template>
  <iframe
    loading="lazy"
    allowfullscreen
    style="width: 100%; border: 0"
    :style="{ height: isOnPC ? '650px' : '300px' }"
    :src="`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${query}`"
  />
</template>

<script>
export default {
  name: 'GoogleMapsEmbedWrapper',

  props: {
    'location-name': String,
    address: String
  },

  computed: {
    apiKey: () => process.env.VUE_APP_GOOGLE_API_KEY,
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    query () {
      var locationName = this.locationName.replace(/ /g, '+')
      var address = this.address.replace(/ /g, '+')
      return (`${locationName},${address}`)
    }
  }
}
</script>
