<template>
  <span>
    <span v-if="value.length">
      <v-btn color="primary" x-large icon v-if="type  === 'instagram'" :href="value"><v-icon :title="type" >mdi-instagram</v-icon></v-btn>
      <v-btn color="primary" x-large icon v-else-if="type  === 'whatsApp'"><v-icon :title="type" >mdi-whatsapp</v-icon></v-btn>
      <v-btn color="primary" x-large icon v-else-if="type  === 'facebook'" :href="value"><v-icon :title="type">mdi-facebook</v-icon></v-btn>
      <v-btn color="primary" x-large icon v-else-if="type  === 'telephone'" :href="`tel:${value}`"><v-icon :title="type">mdi-phone</v-icon></v-btn>
      <v-btn color="primary" x-large icon v-else-if="type === 'email'" :href="`mailto:${value}`"><v-icon :title="type">mdi-email</v-icon></v-btn>
      <v-btn color="primary" x-large icon v-else-if="type === 'website'" :href="value"><v-icon :title="type">mdi-web</v-icon></v-btn>
      <v-btn color="primary" x-large icon v-else><v-icon :title="k">mdi-message-text</v-icon></v-btn>
    </span>
  </span>
</template>

<script>
export default {
  name: 'ContactButton',

  props: {
    type: {
      required: true,
      type: String
    },
    value: {
      required: true,
      value: String
    }
  }
}
</script>
