<template>
  <div>
    <div v-if="day.isOpen" ><v-chip label outlined :color="nowIsOpen ? 'green' : 'red'" v-text="nowIsOpen ? 'Open' : 'Closed'"  :ripple="false" class="text-uppercase" /> Open from {{ day.openingTime }} to {{ day.closingTime }}.</div>
    <div class="font-weight-bold" v-else>Closed today.</div>
  </div>
</template>

<script>
export default {
  name: 'RestaurantOpenStatus',

  props: {
    'business-hours': {
      required: true,
      type: Array
    }
  },

  computed: {
    todayIndex () {
      return (new Date()).getDay()
    },

    day () {
      return this.businessHours.find((day, i) => (i === this.todayIndex))
    },

    nowIsOpen () {
      var openSplit = {
        hour: this.day.openingTime.split(':')[0],
        minute: this.day.openingTime.split(':')[1]
      }
      var closeSplit = {
        hour: this.day.closingTime.split(':')[0],
        minute: this.day.closingTime.split(':')[1]
      }
      var now = new Date()

      var open = (new Date()).setHours(openSplit.hour, openSplit.minute)
      var close = (new Date()).setHours(closeSplit.hour, closeSplit.minute)

      return (
        this.day.isOpen &&
        now > open &&
        now < close
      )
    }
  }
}
</script>
