<template>
  <div class="d-inline-flex align-center">
    <!-- <div
      v-for="(value, k) in contacts" :key="`${value}${k}`"
      :class="{
        'mr-1': !isOnPC,
        'mr-3': isOnPC
      }"
      class="text--secondary"
    >
      <v-icon :title="k" v-if="k=='Instagram'">mdi-instagram</v-icon>
      <v-icon :title="k" v-else-if="k=='WhatsApp'">mdi-whatsapp</v-icon>
      <v-icon :title="k" v-else-if="k=='Facebook'">mdi-facebook</v-icon>
      <v-icon :title="k" v-else-if="k=='Telephone'">mdi-telephone</v-icon>
      <v-icon :title="k" v-else-if="k=='E-mail'">mdi-email</v-icon>
      <v-icon :title="k" v-else>mdi-message-text</v-icon>
      {{ value }}
    </div> -->

    <!-- <span v-for="(value, k) in contacts" :key="`${value}${k}`">
      <span v-if="value.length">
        <v-btn color="primary" x-large icon v-if="k==='instagram'" :href="value"><v-icon :title="k" >mdi-instagram</v-icon></v-btn>
        <v-btn color="primary" x-large icon v-else-if="k==='whatsApp'"><v-icon :title="k" >mdi-whatsapp</v-icon></v-btn>
        <v-btn color="primary" x-large icon v-else-if="k==='facebook'" :href="value"><v-icon :title="k">mdi-facebook</v-icon></v-btn>
        <v-btn color="primary" x-large icon v-else-if="k==='telephone'" :href="`tel:${value}`"><v-icon :title="k">mdi-phone</v-icon></v-btn>
        <v-btn color="primary" x-large icon v-else-if="k==='email'" :href="`mailto:${value}`"><v-icon :title="k">mdi-email</v-icon></v-btn>
        <v-btn color="primary" x-large icon v-else-if="k==='website'" :href="value"><v-icon :title="k">mdi-web</v-icon></v-btn>
        <v-btn color="primary" x-large icon v-else><v-icon :title="k">mdi-message-text</v-icon></v-btn>
      </span>
    </span> -->

    <ContactButton v-for="(value, k) in contacts" :key="value" :type="k" :value="value" />
  </div>
</template>

<script>
import ContactButton from './RestaurantContactButton'

export default {
  name: 'VendorContacts',
  props: {
    contacts: {
      type: Object,
      required: true
    }
  },

  components: {
    ContactButton
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  }
}
</script>
