<template>
  <div>
    <v-simple-table dense>
      <v-progress-circular v-if="loading" />
      <tbody v-else>
        <tr :class="{ 'font-weight-bold': isActiveDay(n), 'text--disabled': !day.isOpen }" v-for="(day, n) in businessHours.hours" :key="day.name">
          <td>{{ day.name }}</td>
          <td>
            <span v-if="!day.isOpen">Closed</span>
            <span v-else>
              {{ day.openingTime }} &ndash; {{ day.closingTime }}
            </span>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <p v-text="businessHours.notes" />
  </div>
</template>

<script>
import firebase from '@/plugins/firebase'

export default {
  name: 'OpeningHour',

  props: {
    'restaurant-id': {
      required: true,
      type: String
    }
  },

  computed: {
    docPath () {
      return `/restaurants/${this.restaurantId}`
    }
  },

  data () {
    return ({
      loading: false,
      businessHours: {
        hours: [], notes: ''
      }
    })
  },

  methods: {
    getRestaurantData () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(this.docPath).get()
          .then(doc => { resolve(doc.data()) })
          .catch(e => { reject(e) })
      })
    },
    isActiveDay (day) {
      var todayDoW = (new Date()).getDay()
      return day === todayDoW
    }
  },

  async mounted () {
    this.$data.loading = true
    var restaurantData
    try {
      restaurantData = await this.getRestaurantData()
      this.$data.businessHours = Object.assign({}, restaurantData.businessHours)
    } catch (e) {
      this.$emit('error', e)
    }
    this.$data.loading = false
  }
}
</script>
